<template>
  <div class="head flex">
    <div class="head-logo">
      <img src="../assets/images/logo.png" alt />
    </div>
    <div class="header-box" ref="routerBox">
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        :router="true"
        active-text-color="#ffd04b"
        class="head-list"
      >
        <el-menu-item :index="'0'" style="display: none"></el-menu-item>
        <template v-for="(item, index) in navData">
          <el-menu-item
            :index="index + 1 + ''"
            :route="{ name: item.navLink }"
            :key="index"
            >{{ item.navName }}</el-menu-item
          >
        </template>
        <el-menu-item index="6" :route="{ name: 'UserHome' }" v-if="getToken"
          >个人中心</el-menu-item
        >
      </el-menu>
    </div>
    <el-button plain class="head-btn" @click="showTabs">导航</el-button>
    <el-button type="warning" v-if="!getToken" @click="skip()"
      >登录/注册</el-button
    >
    <el-dropdown :hide-on-click="false" v-if="getToken">
      <span class="el-dropdown-link">
        <img src="../assets/images/ceo-pic.png" alt />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="outLogin()">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      clientWidth: null,
      navData: [
        { id: 0, navName: "首页", navLink: "Index", isShow: true },
        { id: 1, navName: "产品中心", navLink: "Products", isShow: true },
        { id: 2, navName: "解决方案", navLink: "Solutions", isShow: true },
        { id: 3, navName: "知识库", navLink: "Articles", isShow: true },
        { id: 4, navName: "联系我们", navLink: "Contactus", isShow: true },
        // { id: 5, navName: '个人中心', navLink: 'UserHome', isShow: false }
      ],
      activeIndex: "0",
    };
  },
  computed: {
    ...mapGetters(["getToken", "getLastPath"]),
  },
  methods: {
    // 退出登录
    outLogin() {
      this.$store.commit("logout");
      this.$router.push({ name: "Login" });
    },
    showTabs() {
      if (this.$refs.routerBox.style.display === "block") {
        this.$refs.routerBox.style.display = "none";
      } else {
        this.$refs.routerBox.style.display = "block";
      }
    },
    // 跳转登录注册页面
    skip() {
      if (!this.isWeixinBrowser()) {
        this.$store.commit("setLastPath", this.$route.fullPath);
      }
      this.$router.push({ name: "Login" });
    },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
  },
  mounted() {
    this.clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    window.addEventListener("resize", () => {
      this.clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (this.clientWidth >= 900) {
        this.$refs.routerBox.style.display = "block";
      } else {
        this.$refs.routerBox.style.display = "none";
      }
    });
    const _thisVue = this;
    const storageHandler = function () {
      _thisVue.$store.commit("syncToken", {
        LbjtAccessToken: localStorage.LbjtAccessToken || null,
        LbjtTokenType: localStorage.LbjtTokenType || null,
        LbjtExpiresTime: localStorage.LbjtExpiresTime || null,
      });
    };
    window.addEventListener("storage", storageHandler, false);
  },
  watch: {
    getToken(val, oldVal) {},
    $route(to, from) {
      this.$store.commit("syncToken", {
        LbjtAccessToken: localStorage.LbjtAccessToken || null,
        LbjtTokenType: localStorage.LbjtTokenType || null,
        LbjtExpiresTime: localStorage.LbjtExpiresTime || null,
      });
      if (this.clientWidth < 900) {
        this.$refs.routerBox.style.display = "none";
      }
      this.activeIndex = "0";
      this.navData.map((item, key) => {
        if (to.name === item.navLink) {
          this.activeIndex = String(key + 1);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.head {
  width: @global-min-width;
  margin: 0 auto;
  padding-top: 20px;
  position: relative;
  // display: flex;
  // justify-content: center;
  .head-logo {
    margin-right: 5%;
    img {
      width: 110px;
    }
  }
  .head-list {
    display: flex;
    text-align: center;
    align-items: center;
    border: none;
    margin-left: 100px;
    li {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      /*margin-left: 30px;*/
      a {
        display: inline-block;
        line-height: 50px;
        width: 100%;
      }
    }
  }
  .el-button {
    position: absolute;
    padding: 5px 10px;
    margin-left: 10px;
    right: 50px;
  }
  .head-btn {
    display: none;
  }
  .el-dropdown {
    margin-left: 0.5rem;
    img {
      width: 30px;
    }
  }
}

// 适应移动端
@media screen and (min-width: 900px) and (max-width: 1100px) {
  .head {
    width: auto;
    .head-btn {
      display: none;
    }
    .el-button {
      position: static;
    }
  }
}
@media screen and (max-width: 900px) {
  .head {
    width: auto;
    padding: 20px 30px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .header-box {
      width: 100%;
      position: absolute;
      top: 80px;
      left: 0;
      z-index: 999;
      flex-direction: column;
      align-content: center;
      background: @global-white;
      padding: 0;
      margin-left: 0;
      .head-list {
        width: 100%;
        /*position: absolute;*/
        /*top: 80px;*/
        /*left: 0;*/
        /*z-index: 999;*/
        flex-direction: column;
        align-content: center;
        background: @global-white;
        padding: 0;
        margin-left: 0;
        li {
          width: 100%;
          border-bottom: 1px solid #f2f2f2 !important;
          text-align: center;
        }
        .is-active {
          border-bottom: 2px solid #ffd04b !important;
        }
      }
    }
    .el-button {
      position: static;
    }
    .head-btn {
      display: block;
      height: 40px;
      padding: 0 20px;
    }
  }
}
</style>
