import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
/* iconfont的symbol引入 */
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/display.css'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/Iconfont/iconfont.js'
import './assets/Iconfont_new/iconfont.js'
// import './assets/less/common.less'
import animated from 'animate.css'
Vue.use(animated)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
