export const getToken = state => {
  if (state.login.LbjtAccessToken && state.login.LbjtTokenType) {
    return state.login.LbjtTokenType + " " + state.login.LbjtAccessToken;
  }
  if (localStorage.getItem('LbjtAccessToken') && localStorage.getItem('LbjtTokenType')) {
    return localStorage.getItem('LbjtTokenType') + " " + localStorage.getItem('LbjtAccessToken');
  }
  if (localStorage.LbjtAccessToken && localStorage.LbjtTokenType) {
    return localStorage.LbjtTokenType + " " + localStorage.LbjtAccessToken;
  }
  return null;
};
export const getLastPath = state => {
  return localStorage.getItem('LbjtlastPath') || localStorage.LbjtlastPath || state.lastPath;
};
export const getWxOpenId = state => {
  return localStorage.getItem('LbjtWxOpenId') || localStorage.LbjtWxOpenId || state.wxOpenId;
};
export const showDialog = state => {
  return state.show;
};
