import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import * as actions from "./store/actions";
import * as mutations from "./store/mutations";
import * as getters from "./store/getters";
import router from "./router";
import { Message, Loading } from "element-ui";
import errorCode from "./errorCode";
// axios.create({
//   timeout: 1 // 请求超时时间
// });
Vue.use(Vuex);
Vue.prototype.$http = axios;
// 添加请求拦截器
let loadingInstance = null;

axios.interceptors.request.use(
  function (config) {

    config.headers.Accept = `application/json`;
    config.headers.ContentType = `application/x-www-form-urlencoded`;
    if (
      localStorage.getItem("LbjtTokenType") ||
      localStorage.getItem("LbjtAccessToken")
    ) {
      config.headers.Authorization =
        //localStorage.getItem("LbjtTokenType") +
        `Bearer` + " " + localStorage.getItem("LbjtAccessToken");
    }
    loadingInstance = Loading.service({
      lock: true,
      text: "正在加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.3)",
    });
    return config;
  },
  function (err) {
    console.log(err);
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    loadingInstance.close();
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200;
    // 获取错误信息
    const msg =
      errorCode[code] ||
      response.data.message ||
      response.message ||
      response.data.msg ||
      response.msg ||
      response.data.code ||
      errorCode["default"];
    switch (code) {
      case 200:
        return response;
      default:
        Message.error({ message: msg, offset: 100, showClose: true });
        return response;
    }
  },
  function (error) {
    loadingInstance.close();
    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
    let message = "";
    let dataMessage;
    dataMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.data?.message ||
      error?.data?.msg ||
      error?.response?.message ||
      error?.response?.msg ||
      "出错了";
    let status = error?.response?.status || error?.response?.code;
    switch (status) {
      case 401:
        store.commit("logout");
        router.push({ name: "Login" });
        message = dataMessage;
        break;
      case 400:
      case 403:
      case 422:
        message = dataMessage;
        break;
      default:
        if (dataMessage == "Network Error") {
          message = "后端接口连接异常";
        } else if (dataMessage.indexOf("timeout") != -1) {
          message = "系统接口请求超时";
        } else if (
          dataMessage.indexOf("Request failed with status code") != -1
        ) {
          message =
            "系统接口" + dataMessage.substr(dataMessage.length - 3) + "异常";
        } else {
          message = dataMessage;
        }
        break;
    }
    message ? message : (message = "出错了");
    console.log(status, dataMessage, message);
    Message.error({ message: message, offset: 100, showClose: true });
    return Promise.reject(error);
  }
);

let state = {
  storageToken: null,
  lastPath: null,
  login: {},
  wxOpenId: null,
  // 存储订单id 和 文章id
  storageId: {},
};
// console.log('state:', state.storageId)
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
export default store;
